export enum EnumGeneric {
    SESSION = "$Qsesion",
    SESSION_PROFILE = "profile",
    SESSION_RESET = "reset",
    SESSION_FULLNAME = "fullname",
    SESSION_EXPIRES = "expires",
    SESSION_FACILITY = "facility",
    SESSION_PERSONID = "personid",
    SESSION_LASTACCESS = "lastaccess",
    SESSION_APICHAT = "apichat",
}
export enum EnumAPI {
    PATIENT = "/patient",
    PROVIDER = "/provider",
    STAFF = "/staff",
    FACILITY = "/facility",
    FACILITY_USER = "/facusers",
    TYPE_VISIT = "/settings/type_visit",
    TYPE_VISITS = "/settings/type_visits",
    TYPE_FACILITY = "/settings/type_facility",
    DX_CODE = "/settings/dx_code",
    BX_CODE = "/settings/bx_code",
    REPORT_VISIT = "/report/visit"
}

export enum EnumRoutes {

    PATIENT_LIST_FULL = "/patient/full",
    PATIENT_LIST = "/patient/list",
    PATIENT_NEW = "/patient/new",
    PATIENT_UPDATE = "/patient/update",
    PATIENT_ACTIVATE = "/patient/activate",
    PATIENT_INACTIVES = "/patient/inactives",
    PATIENT_IMPORT = "/patient/import",
    PATIENT_VISIT = "/patient/visit",

    PROVIDER_LIST = "/provider/list",
    PROVIDER_NEW = "/provider/new",
    PROVIDER_UPDATE = "/provider/update",
    PROVIDER_INACTIVES = "/provider/patient/inactives",
    STAFF_LIST = "/staff/list",
    STAFF_NEW = "/staff/new",
    STAFF_UPDATE = "/staff/update",

    FACILITY_LIST = "/facility/list",
    FACILITY_NEW = "/facility/new",
    FACILITY_UPDATE = "/facility/update",

    FACILITY_LIST_USERS = "/facility/users_list",
    FACILITY_LIST_USERS_NEW = "/facility/users_new",
    FACILITY_LIST_USERS_UPDATE = "/facility/users_update",

    FACILITY_USER_LIST = "/facility_user/list",
    FACILITY_USER_NEW = "/facility_user/new",
    FACILITY_USER_UPDATE = "/facility_user/update",

    SCHEDULE_STAFF_MONTH = "/schedule/staff_month",
    SCHEDULE_STAFF_DAY = "/schedule/staff_day",
    SCHEDULE_STAFF_WEEK = "/schedule/staff_week",
    SCHEDULE_STAFF_HOUR = "/schedule/staff_hour",
    SCHEDULE_STAFF_DETAIL = "/schedule/staff_detail",
    SCHEDULE_PROVIDER_MONTH = "/schedule/provider_month",
    SCHEDULE_PROVIDER_WEEK = "/schedule/provider_week",
    SCHEDULE_PROVIDER_HOUR = "/schedule/provider_hour",
    SCHEDULE_PROVIDER_DAY = "/schedule/provider_day",
    SCHEDULE_PROVIDER_DETAIL = "/schedule/provider_detail",
    SCHEDULE_FACILITY_MONTH = "/schedule/facility_month",
    SCHEDULE_FACILITY_WEEK = "/schedule/facility_week",
    SCHEDULE_FACILITY_DAY = "/schedule/facility_day",
    SCHEDULE_FACILITY_DETAIL = "/schedule/facility_detail",

    VISIT_TO_ASSIGN = "/visit/to_assign",
    VISIT_TO_REASSIGN = "/visit/to_reassign",
    VISIT_TO_SIGN = "/visit/to_sign",
    VISIT_TO_EVAL = "/visit/to_eval",
    VISIT_TO_BILLING = "/visit/to_billing",
    VISIT_NEW_FROM_FACILITY = "/visit/from_facility",
    VISIT_NEW_FROM_PROVIDER = "/visit/from_provider",
    VISIT_UPDATE_FROM_PROVIDER = "/visit/update/from_provider",
    VISIT_ASSIGN = "/visit/assign",
    VISIT_REASSIGN = "/visit/reassign",
    VISIT_ASSIGN_REMOVAL = "/visit/assign/remove",
    VISIT_REASSIGN_REMOVAL = "/visit/reassign/remove",
    VISIT_EVAL = "/visit/eval",

    REPORT_VISIT_FOR_PROVIDER = "/report/visit/provider",
    REPORT_VISIT_FOR_FACILITY = "/report/visit/facility",
    REPORT_VISIT_FOR_STAFF = "/report/visit/staff",
    REPORT_VISIT_DETAIL = "/report/visit/detail",

    SETTINGS_TYPE_VISIT = "/settings/type_visit",
    SETTINGS_TYPE_FACILITY = "/settings/type_facility",
    SETTINGS_DX = "/settings/dx_code",
    SETTINGS_BX = "/settings/bx_code",

    LOG_TRACKING = "/log/tracking/list",

    CHAT = "/chat",
    LOGOUT = "/logout",
    LOGIN = "/login"
}

export enum EnumProfiles {
    ADMIN = 1,
    STAFF = 2,
    PROVIDER = 3,
    FACILITY = 4,
    PATIENT = 5,
}