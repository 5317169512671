// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL:'https://development.spartanltc.com:4500/api',
  DIR_URL:'https://development.spartanltc.com',
  COMETCHAT_API_ID:'3129651bd32b374',
  COMETCHAT_API_KEY:'92f0bfb78ce4422a2edbb7a65922f70649d48290',
  COMETCHAT_API_REGION:'US',
  COMETCHAT_API_REST_URL:'https://api-us.cometchat.io/v2.0',
  COMETCHAT_API_REST_KEY:'62471c747b6febcbcf16051bcffc974a5c47bbfe'
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  