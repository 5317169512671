import { Injectable } from '@angular/core';
import { Output, EventEmitter } from '@angular/core'
import { EnumGeneric } from 'src/app/models/enums'
import { Session } from 'src/app/models/session'
import * as CryptoJS from 'crypto-js';
import { MilaTable } from 'src/app/mila/models/mila-table';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  @Output() isLogin = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  encryptedLocal:string;
  encryptedKey: string;
  constructor() { 
    this.encryptedKey="%^C2rl0s_Sánch3z-Sp@rt@n*(2020)!"
    this.encryptedLocal="P0rLáM@m2D3l-Ch?inn[]-*2020!)"
  }

  private encrypt(_text:string){
    return CryptoJS.AES.encrypt(_text, this.encryptedKey).toString();
  }
  private decrypt(_text:string){
    try{
      return CryptoJS.AES.decrypt(_text, this.encryptedKey).toString(CryptoJS.enc.Utf8);
    }catch{
      return null;
    }
  }

  private setAttribute(nameAttribute:string, valueAttribute:string){
    if(sessionStorage){
      sessionStorage.setItem(nameAttribute, this.encrypt(valueAttribute));
    }    
  }
  private getAttribute(nameAttribute:string){
    if(sessionStorage && sessionStorage.getItem(nameAttribute)){
      return this.decrypt(sessionStorage.getItem(nameAttribute));
    }
    return null;
  }

  private getSession():Session{
    if(sessionStorage && sessionStorage.getItem(EnumGeneric.SESSION)){
      try{
        return JSON.parse(this.decrypt(sessionStorage.getItem(EnumGeneric.SESSION))) as Session;
      }catch(e){
        return null;
      }
    }
    return null;
  }
  
  public initSession(data:string){
    this.closeSession();
    this.setAttribute(EnumGeneric.SESSION,data);
    this.updateLogin(true);
  }

  public closeSession(){
    this.updateLogin(false);
    sessionStorage.clear();    
    localStorage.clear();
  }

  public updateLogin(status:boolean) {
    this.isLogin = status;
    this.change.emit(this.isLogin);
  }
 
  public getSessionAttribute(attribute:string):string{
    var _session=this.getSession();
    if(_session){
      switch(attribute){
        case EnumGeneric.SESSION_FULLNAME:
          return _session.fullname+""
        case EnumGeneric.SESSION_EXPIRES:
          return _session.expires+""
        case EnumGeneric.SESSION_LASTACCESS:
          return _session.lastaccess+""
        case EnumGeneric.SESSION_PROFILE:
          return _session.profile+""
        case EnumGeneric.SESSION_PERSONID:
          return _session.personid+""
        case EnumGeneric.SESSION_FACILITY:
          return _session.facility+""
        case EnumGeneric.SESSION_RESET:
            return _session.reset+""
        case EnumGeneric.SESSION_APICHAT:
            return _session.apichat+""
        default: return null;
      }
    }
    return null;
  }

  public existSession():Boolean{
    var _session=this.getSession();
    if(_session){
      var _expires=new Date(_session.expires);      
      var _now=new Date();
      if(_expires.getTime()>_now.getTime()){
        return true;
      }
      this.closeSession();
      return false;
    }
    return false;
  }

  private setLocalAttribute(name:string, value:string){
    localStorage.setItem(name, this.encrypt(value));
  }

  private getLocalAttribute(name:string){
    if(localStorage && localStorage.getItem(name)){
      return this.decrypt(localStorage.getItem(name));
    }
    return null;
  }

  public clearDataFP(){
    localStorage.removeItem("DataF");
    localStorage.removeItem("DataT");
    localStorage.removeItem("DataFS");
    localStorage.removeItem("DataTS");
  }

  public setDataFilters(filterForm){ 
    this.setLocalAttribute('DataF', JSON.stringify(filterForm));
  }
  public getDataFilters(){
    return this.getLocalAttribute('DataF');
  }

  public setDataFiltersSecond(filterForm){ 
    this.setLocalAttribute('DataFS', JSON.stringify(filterForm));
  }
  public getDataFiltersSecond(){
    return this.getLocalAttribute('DataFS');
  }

  public setDataTable(tableForm:MilaTable){ 
    var table={"page":tableForm.page, "sort":tableForm.sort, "sortOrder":tableForm.sortOrder, "size":tableForm.pageSize}
    this.setLocalAttribute('DataT', JSON.stringify(table));
  }
  public getDataTable(tableForm:MilaTable){
    var table=JSON.parse(this.getLocalAttribute('DataT'));
    if(table){
      tableForm.page=table.page;
      tableForm.pageSize=table.size;
      tableForm.sort=table.sort;      
      tableForm.sortOrder=table.sortOrder;
    }
    return tableForm;
  }

  public setDataTableSecond(tableForm:MilaTable){ 
    var table={"page":tableForm.page, "sort":tableForm.sort, "sortOrder":tableForm.sortOrder, "size":tableForm.pageSize}
    this.setLocalAttribute('DataTS', JSON.stringify(table));
  }
  public getDataTableSecond(tableForm:MilaTable){
    var table=JSON.parse(this.getLocalAttribute('DataTS'));
    if(table){
      tableForm.page=table.page;
      tableForm.pageSize=table.size;
      tableForm.sort=table.sort;      
      tableForm.sortOrder=table.sortOrder;
    }
    return tableForm;
  }


  public setDataExtra(attrName, attrValue){ 
    this.setLocalAttribute(attrName, attrValue);
  }
  public getDataExtra(attrName){ 
    return this.getLocalAttribute(attrName);
  }

}
