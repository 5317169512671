import { EnumRoutes, EnumProfiles } from 'src/app/models/enums'
import { Menu, MenuItem, MenuAccess } from './menu'

export var DataMenu: Menu[] = [

    //ADMIN
    { id: 10, profile: EnumProfiles.ADMIN, label: 'Directories', icon: 'sidebar-icon icn-directory', complex: true },
    { id: 20, profile: EnumProfiles.ADMIN, label: 'Settings', icon: 'sidebar-icon icn-setting', complex: true },
    { id: 30, profile: EnumProfiles.ADMIN, label: 'Exit', icon: 'sidebar-icon icn-exit', link: EnumRoutes.LOGOUT },

    //STAFF
    { id: 10, profile: EnumProfiles.STAFF, label: 'Visit Schedule', icon: 'sidebar-icon icn-visit-schedule', link: EnumRoutes.SCHEDULE_STAFF_WEEK },
    { id: 20, profile: EnumProfiles.STAFF, label: 'Assign Visit', icon: 'sidebar-icon icn-visit-assign', link: EnumRoutes.VISIT_TO_ASSIGN },
    { id: 30, profile: EnumProfiles.STAFF, label: 'Facility Request', icon: 'sidebar-icon icn-visit-eval', link: EnumRoutes.VISIT_TO_EVAL },
    { id: 40, profile: EnumProfiles.STAFF, label: 'Billing', icon: 'sidebar-icon icn-visit-billing', link: EnumRoutes.VISIT_TO_BILLING },
    { id: 50, profile: EnumProfiles.STAFF, label: 'Directory', icon: 'sidebar-icon icn-directory', complex: true },
    { id: 60, profile: EnumProfiles.STAFF, label: 'Report', icon: 'sidebar-icon icn-report', link: EnumRoutes.REPORT_VISIT_FOR_STAFF },
    { id: 70, profile: EnumProfiles.STAFF, label: 'Settings', icon: 'sidebar-icon icn-setting', complex: true },
    { id: 80, profile: EnumProfiles.STAFF, label: 'Logs', icon: 'sidebar-icon icn-report', link: EnumRoutes.LOG_TRACKING },
    { id: 90, profile: EnumProfiles.STAFF, label: 'Chat', icon: 'sidebar-icon icn-chat', link: EnumRoutes.CHAT },
    { id: 100, profile: EnumProfiles.STAFF, label: 'Exit', icon: 'sidebar-icon icn-exit', link: EnumRoutes.LOGOUT },

    //PROVIDER
    { id: 10, profile: EnumProfiles.PROVIDER, label: 'Visit Schedule', icon: 'sidebar-icon icn-visit-schedule', link: EnumRoutes.SCHEDULE_PROVIDER_WEEK },
    { id: 20, profile: EnumProfiles.PROVIDER, label: 'Sign Visit', icon: 'sidebar-icon icn-visit-sign', link: EnumRoutes.VISIT_TO_SIGN },
    { id: 30, profile: EnumProfiles.PROVIDER, label: 'Add New Visit', icon: 'sidebar-icon icn-visit-new', link: EnumRoutes.VISIT_NEW_FROM_PROVIDER },
    { id: 40, profile: EnumProfiles.PROVIDER, label: 'Patients', icon: 'sidebar-icon icn-directory', link: EnumRoutes.PATIENT_LIST },
    { id: 50, profile: EnumProfiles.PROVIDER, label: 'Report', icon: 'sidebar-icon icn-report', link: EnumRoutes.REPORT_VISIT_FOR_PROVIDER },
    { id: 60, profile: EnumProfiles.PROVIDER, label: 'Chat', icon: 'sidebar-icon icn-chat', link: EnumRoutes.CHAT },
    { id: 70, profile: EnumProfiles.PROVIDER, label: 'Exit', icon: 'sidebar-icon icn-exit', link: EnumRoutes.LOGOUT },

    //FACILITY
    { id: 10, profile: EnumProfiles.FACILITY, label: 'Visit Schedule', icon: 'sidebar-icon icn-visit-schedule', link: EnumRoutes.SCHEDULE_FACILITY_WEEK },
    { id: 20, profile: EnumProfiles.FACILITY, label: 'Add New Visit', icon: 'sidebar-icon icn-visit-new', link: EnumRoutes.VISIT_NEW_FROM_FACILITY },
    { id: 30, profile: EnumProfiles.FACILITY, label: 'Report', icon: 'sidebar-icon icn-report', link: EnumRoutes.REPORT_VISIT_FOR_FACILITY },
    { id: 40, profile: EnumProfiles.FACILITY, label: 'Chat', icon: 'sidebar-icon icn-chat', link: EnumRoutes.CHAT },
    { id: 50, profile: EnumProfiles.FACILITY, label: 'Exit', icon: 'sidebar-icon icn-exit', link: EnumRoutes.LOGOUT }
]
export var DataItem: MenuItem[] = [

    //ADMIN
    { parent: 10, profile: EnumProfiles.ADMIN, label: 'Patients', link: EnumRoutes.PATIENT_LIST_FULL },
    { parent: 10, profile: EnumProfiles.ADMIN, label: 'Providers', link: EnumRoutes.PROVIDER_LIST },
    { parent: 10, profile: EnumProfiles.ADMIN, label: 'Staff', link: EnumRoutes.STAFF_LIST },
    { parent: 10, profile: EnumProfiles.ADMIN, label: 'Facilities', link: EnumRoutes.FACILITY_LIST },
    { parent: 10, profile: EnumProfiles.ADMIN, label: 'Facility Users', link: EnumRoutes.FACILITY_USER_LIST },
    { parent: 20, profile: EnumProfiles.ADMIN, label: 'Visit Type', link: EnumRoutes.SETTINGS_TYPE_VISIT },
    { parent: 20, profile: EnumProfiles.ADMIN, label: 'Facility Type', link: EnumRoutes.SETTINGS_TYPE_FACILITY },
    { parent: 20, profile: EnumProfiles.ADMIN, label: 'Dx Code', link: EnumRoutes.SETTINGS_DX },
    { parent: 20, profile: EnumProfiles.ADMIN, label: 'Billing Code', link: EnumRoutes.SETTINGS_BX },

    //STAFF
    { parent: 50, profile: EnumProfiles.STAFF, label: 'Patients', link: EnumRoutes.PATIENT_LIST_FULL },
    { parent: 50, profile: EnumProfiles.STAFF, label: 'Providers', link: EnumRoutes.PROVIDER_LIST },
    { parent: 50, profile: EnumProfiles.STAFF, label: 'Staff', link: EnumRoutes.STAFF_LIST },
    { parent: 50, profile: EnumProfiles.STAFF, label: 'Facilities', link: EnumRoutes.FACILITY_LIST },
    { parent: 50, profile: EnumProfiles.STAFF, label: 'Facility Users', link: EnumRoutes.FACILITY_USER_LIST },
    //{parent:60, profile:EnumProfiles.STAFF, label:'Visit Report',    link:EnumRoutes.REPORT_VISIT_FOR_STAFF},
    { parent: 70, profile: EnumProfiles.STAFF, label: 'Visit Type', link: EnumRoutes.SETTINGS_TYPE_VISIT },
    { parent: 70, profile: EnumProfiles.STAFF, label: 'Facility Type', link: EnumRoutes.SETTINGS_TYPE_FACILITY },
    { parent: 70, profile: EnumProfiles.STAFF, label: 'Dx Code', link: EnumRoutes.SETTINGS_DX },
    { parent: 70, profile: EnumProfiles.STAFF, label: 'Billing Code', link: EnumRoutes.SETTINGS_BX },
    { parent: 70, profile: EnumProfiles.STAFF, label: 'Log Tracking', link: EnumRoutes.LOG_TRACKING },

    //PROVIDER
    //{parent:50, profile:EnumProfiles.PROVIDER, label:'Visit Report',    link:EnumRoutes.REPORT_VISIT_FOR_PROVIDER}
]

export var DataAccess: MenuAccess[] = [
    //ADMIN
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.PROVIDER_LIST },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.PROVIDER_NEW },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.PROVIDER_UPDATE },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.STAFF_LIST },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.STAFF_NEW },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.STAFF_UPDATE },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.PATIENT_LIST_FULL },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.PATIENT_NEW },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.PATIENT_UPDATE },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.PATIENT_ACTIVATE },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.PATIENT_INACTIVES },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.PATIENT_IMPORT },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.PATIENT_VISIT },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.FACILITY_LIST },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.FACILITY_LIST_USERS },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.FACILITY_LIST_USERS_NEW },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.FACILITY_LIST_USERS_UPDATE },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.FACILITY_NEW },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.FACILITY_UPDATE },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.FACILITY_USER_LIST },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.FACILITY_USER_NEW },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.FACILITY_USER_UPDATE },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.REPORT_VISIT_FOR_STAFF },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.SETTINGS_BX },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.SETTINGS_DX },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.SETTINGS_TYPE_FACILITY },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.SETTINGS_TYPE_VISIT },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.LOGOUT },
    { profile: EnumProfiles.ADMIN, link: EnumRoutes.LOG_TRACKING },

    //STAFF
    { profile: EnumProfiles.STAFF, link: EnumRoutes.SCHEDULE_STAFF_WEEK },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.SCHEDULE_STAFF_MONTH },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.SCHEDULE_STAFF_HOUR },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.SCHEDULE_STAFF_DAY },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.SCHEDULE_STAFF_DETAIL },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.VISIT_TO_ASSIGN },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.VISIT_ASSIGN },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.VISIT_REASSIGN },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.VISIT_TO_REASSIGN },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.VISIT_TO_BILLING },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.VISIT_TO_EVAL },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.VISIT_EVAL },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.PROVIDER_LIST },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.PROVIDER_NEW },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.PROVIDER_UPDATE },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.STAFF_LIST },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.STAFF_NEW },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.STAFF_UPDATE },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.PATIENT_LIST_FULL },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.PATIENT_NEW },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.PATIENT_UPDATE },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.PATIENT_ACTIVATE },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.PATIENT_INACTIVES },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.PATIENT_VISIT },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.PATIENT_IMPORT },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.FACILITY_LIST },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.FACILITY_LIST_USERS },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.FACILITY_LIST_USERS_NEW },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.FACILITY_LIST_USERS_UPDATE },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.FACILITY_NEW },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.FACILITY_UPDATE },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.FACILITY_USER_LIST },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.FACILITY_USER_NEW },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.FACILITY_USER_UPDATE },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.REPORT_VISIT_FOR_STAFF },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.REPORT_VISIT_DETAIL },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.SETTINGS_BX },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.SETTINGS_DX },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.SETTINGS_TYPE_FACILITY },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.SETTINGS_TYPE_VISIT },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.LOG_TRACKING },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.CHAT },
    { profile: EnumProfiles.STAFF, link: EnumRoutes.LOGOUT },

    //PROVIDER
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.SCHEDULE_PROVIDER_WEEK },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.SCHEDULE_PROVIDER_MONTH },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.SCHEDULE_PROVIDER_DAY },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.SCHEDULE_PROVIDER_HOUR },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.SCHEDULE_PROVIDER_DETAIL },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.VISIT_TO_SIGN },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.VISIT_UPDATE_FROM_PROVIDER },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.VISIT_NEW_FROM_PROVIDER },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.PATIENT_LIST },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.PATIENT_NEW },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.PATIENT_UPDATE },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.REPORT_VISIT_FOR_PROVIDER },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.REPORT_VISIT_DETAIL },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.CHAT },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.LOGOUT },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.LOG_TRACKING },
    { profile: EnumProfiles.PROVIDER, link: EnumRoutes.PROVIDER_INACTIVES },
    //FACILITY
    { profile: EnumProfiles.FACILITY, link: EnumRoutes.SCHEDULE_FACILITY_WEEK },
    { profile: EnumProfiles.FACILITY, link: EnumRoutes.SCHEDULE_FACILITY_MONTH },
    { profile: EnumProfiles.FACILITY, link: EnumRoutes.SCHEDULE_FACILITY_DAY },
    { profile: EnumProfiles.FACILITY, link: EnumRoutes.SCHEDULE_FACILITY_DETAIL },
    { profile: EnumProfiles.FACILITY, link: EnumRoutes.VISIT_NEW_FROM_FACILITY },
    { profile: EnumProfiles.FACILITY, link: EnumRoutes.REPORT_VISIT_FOR_FACILITY },
    { profile: EnumProfiles.FACILITY, link: EnumRoutes.CHAT },
    { profile: EnumProfiles.FACILITY, link: EnumRoutes.LOGOUT },
]